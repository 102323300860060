/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as PublicImport } from './routes/_public'
import { Route as MainImport } from './routes/_main'
import { Route as ParcoursupCallbackIndexImport } from './routes/parcoursup-callback/index'
import { Route as ConnexionIndexImport } from './routes/connexion/index'
import { Route as MainIndexImport } from './routes/_main/index'
import { Route as MainMainImport } from './routes/_main/_main'
import { Route as PublicFormationIndexImport } from './routes/_public/formation/index'
import { Route as MainFormationsIndexImport } from './routes/_main/formations/index'
import { Route as MainFavorisIndexImport } from './routes/_main/favoris/index'
import { Route as MainEleveInscriptionImport } from './routes/_main/eleve/_inscription'

// Create Virtual Routes

const MainEleveImport = createFileRoute('/_main/eleve')()
const PublicPlanDuSiteIndexLazyImport = createFileRoute(
  '/_public/plan-du-site/',
)()
const PublicDeclarationAccessibliteIndexLazyImport = createFileRoute(
  '/_public/declaration-accessiblite/',
)()
const PublicCookiesIndexLazyImport = createFileRoute('/_public/cookies/')()
const MainProfilIndexLazyImport = createFileRoute('/_main/profil/')()
const MainEleveInscriptionInscriptionScolariteIndexLazyImport = createFileRoute(
  '/_main/eleve/_inscription/inscription/scolarite/',
)()
const MainEleveInscriptionInscriptionProjetIndexLazyImport = createFileRoute(
  '/_main/eleve/_inscription/inscription/projet/',
)()
const MainEleveInscriptionInscriptionMetiersIndexLazyImport = createFileRoute(
  '/_main/eleve/_inscription/inscription/metiers/',
)()
const MainEleveInscriptionInscriptionInteretsIndexLazyImport = createFileRoute(
  '/_main/eleve/_inscription/inscription/interets/',
)()
const MainEleveInscriptionInscriptionFormationsIndexLazyImport =
  createFileRoute('/_main/eleve/_inscription/inscription/formations/')()
const MainEleveInscriptionInscriptionEtudeIndexLazyImport = createFileRoute(
  '/_main/eleve/_inscription/inscription/etude/',
)()
const MainEleveInscriptionInscriptionDomainesIndexLazyImport = createFileRoute(
  '/_main/eleve/_inscription/inscription/domaines/',
)()
const MainEleveInscriptionInscriptionConfirmationIndexLazyImport =
  createFileRoute('/_main/eleve/_inscription/inscription/confirmation/')()

// Create/Update Routes

const PublicRoute = PublicImport.update({
  id: '/_public',
  getParentRoute: () => rootRoute,
} as any)

const MainRoute = MainImport.update({
  id: '/_main',
  getParentRoute: () => rootRoute,
} as any)

const MainEleveRoute = MainEleveImport.update({
  id: '/eleve',
  path: '/eleve',
  getParentRoute: () => MainRoute,
} as any)

const ParcoursupCallbackIndexRoute = ParcoursupCallbackIndexImport.update({
  id: '/parcoursup-callback/',
  path: '/parcoursup-callback/',
  getParentRoute: () => rootRoute,
} as any)

const ConnexionIndexRoute = ConnexionIndexImport.update({
  id: '/connexion/',
  path: '/connexion/',
  getParentRoute: () => rootRoute,
} as any)

const MainIndexRoute = MainIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => MainRoute,
} as any)

const MainMainRoute = MainMainImport.update({
  id: '/_main',
  getParentRoute: () => MainRoute,
} as any)

const PublicPlanDuSiteIndexLazyRoute = PublicPlanDuSiteIndexLazyImport.update({
  id: '/plan-du-site/',
  path: '/plan-du-site/',
  getParentRoute: () => PublicRoute,
} as any).lazy(() =>
  import('./routes/_public/plan-du-site/index.lazy').then((d) => d.Route),
)

const PublicDeclarationAccessibliteIndexLazyRoute =
  PublicDeclarationAccessibliteIndexLazyImport.update({
    id: '/declaration-accessiblite/',
    path: '/declaration-accessiblite/',
    getParentRoute: () => PublicRoute,
  } as any).lazy(() =>
    import('./routes/_public/declaration-accessiblite/index.lazy').then(
      (d) => d.Route,
    ),
  )

const PublicCookiesIndexLazyRoute = PublicCookiesIndexLazyImport.update({
  id: '/cookies/',
  path: '/cookies/',
  getParentRoute: () => PublicRoute,
} as any).lazy(() =>
  import('./routes/_public/cookies/index.lazy').then((d) => d.Route),
)

const MainProfilIndexLazyRoute = MainProfilIndexLazyImport.update({
  id: '/profil/',
  path: '/profil/',
  getParentRoute: () => MainRoute,
} as any).lazy(() =>
  import('./routes/_main/profil/index.lazy').then((d) => d.Route),
)

const PublicFormationIndexRoute = PublicFormationIndexImport.update({
  id: '/formation/',
  path: '/formation/',
  getParentRoute: () => PublicRoute,
} as any)

const MainFormationsIndexRoute = MainFormationsIndexImport.update({
  id: '/formations/',
  path: '/formations/',
  getParentRoute: () => MainRoute,
} as any)

const MainFavorisIndexRoute = MainFavorisIndexImport.update({
  id: '/favoris/',
  path: '/favoris/',
  getParentRoute: () => MainRoute,
} as any)

const MainEleveInscriptionRoute = MainEleveInscriptionImport.update({
  id: '/_inscription',
  getParentRoute: () => MainEleveRoute,
} as any)

const MainEleveInscriptionInscriptionScolariteIndexLazyRoute =
  MainEleveInscriptionInscriptionScolariteIndexLazyImport.update({
    id: '/inscription/scolarite/',
    path: '/inscription/scolarite/',
    getParentRoute: () => MainEleveInscriptionRoute,
  } as any).lazy(() =>
    import(
      './routes/_main/eleve/_inscription/inscription/scolarite/index.lazy'
    ).then((d) => d.Route),
  )

const MainEleveInscriptionInscriptionProjetIndexLazyRoute =
  MainEleveInscriptionInscriptionProjetIndexLazyImport.update({
    id: '/inscription/projet/',
    path: '/inscription/projet/',
    getParentRoute: () => MainEleveInscriptionRoute,
  } as any).lazy(() =>
    import(
      './routes/_main/eleve/_inscription/inscription/projet/index.lazy'
    ).then((d) => d.Route),
  )

const MainEleveInscriptionInscriptionMetiersIndexLazyRoute =
  MainEleveInscriptionInscriptionMetiersIndexLazyImport.update({
    id: '/inscription/metiers/',
    path: '/inscription/metiers/',
    getParentRoute: () => MainEleveInscriptionRoute,
  } as any).lazy(() =>
    import(
      './routes/_main/eleve/_inscription/inscription/metiers/index.lazy'
    ).then((d) => d.Route),
  )

const MainEleveInscriptionInscriptionInteretsIndexLazyRoute =
  MainEleveInscriptionInscriptionInteretsIndexLazyImport.update({
    id: '/inscription/interets/',
    path: '/inscription/interets/',
    getParentRoute: () => MainEleveInscriptionRoute,
  } as any).lazy(() =>
    import(
      './routes/_main/eleve/_inscription/inscription/interets/index.lazy'
    ).then((d) => d.Route),
  )

const MainEleveInscriptionInscriptionFormationsIndexLazyRoute =
  MainEleveInscriptionInscriptionFormationsIndexLazyImport.update({
    id: '/inscription/formations/',
    path: '/inscription/formations/',
    getParentRoute: () => MainEleveInscriptionRoute,
  } as any).lazy(() =>
    import(
      './routes/_main/eleve/_inscription/inscription/formations/index.lazy'
    ).then((d) => d.Route),
  )

const MainEleveInscriptionInscriptionEtudeIndexLazyRoute =
  MainEleveInscriptionInscriptionEtudeIndexLazyImport.update({
    id: '/inscription/etude/',
    path: '/inscription/etude/',
    getParentRoute: () => MainEleveInscriptionRoute,
  } as any).lazy(() =>
    import(
      './routes/_main/eleve/_inscription/inscription/etude/index.lazy'
    ).then((d) => d.Route),
  )

const MainEleveInscriptionInscriptionDomainesIndexLazyRoute =
  MainEleveInscriptionInscriptionDomainesIndexLazyImport.update({
    id: '/inscription/domaines/',
    path: '/inscription/domaines/',
    getParentRoute: () => MainEleveInscriptionRoute,
  } as any).lazy(() =>
    import(
      './routes/_main/eleve/_inscription/inscription/domaines/index.lazy'
    ).then((d) => d.Route),
  )

const MainEleveInscriptionInscriptionConfirmationIndexLazyRoute =
  MainEleveInscriptionInscriptionConfirmationIndexLazyImport.update({
    id: '/inscription/confirmation/',
    path: '/inscription/confirmation/',
    getParentRoute: () => MainEleveInscriptionRoute,
  } as any).lazy(() =>
    import(
      './routes/_main/eleve/_inscription/inscription/confirmation/index.lazy'
    ).then((d) => d.Route),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_main': {
      id: '/_main'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof MainImport
      parentRoute: typeof rootRoute
    }
    '/_public': {
      id: '/_public'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof PublicImport
      parentRoute: typeof rootRoute
    }
    '/_main/_main': {
      id: '/_main/_main'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof MainMainImport
      parentRoute: typeof MainImport
    }
    '/_main/': {
      id: '/_main/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof MainIndexImport
      parentRoute: typeof MainImport
    }
    '/connexion/': {
      id: '/connexion/'
      path: '/connexion'
      fullPath: '/connexion'
      preLoaderRoute: typeof ConnexionIndexImport
      parentRoute: typeof rootRoute
    }
    '/parcoursup-callback/': {
      id: '/parcoursup-callback/'
      path: '/parcoursup-callback'
      fullPath: '/parcoursup-callback'
      preLoaderRoute: typeof ParcoursupCallbackIndexImport
      parentRoute: typeof rootRoute
    }
    '/_main/eleve': {
      id: '/_main/eleve'
      path: '/eleve'
      fullPath: '/eleve'
      preLoaderRoute: typeof MainEleveImport
      parentRoute: typeof MainImport
    }
    '/_main/eleve/_inscription': {
      id: '/_main/eleve/_inscription'
      path: '/eleve'
      fullPath: '/eleve'
      preLoaderRoute: typeof MainEleveInscriptionImport
      parentRoute: typeof MainEleveRoute
    }
    '/_main/favoris/': {
      id: '/_main/favoris/'
      path: '/favoris'
      fullPath: '/favoris'
      preLoaderRoute: typeof MainFavorisIndexImport
      parentRoute: typeof MainImport
    }
    '/_main/formations/': {
      id: '/_main/formations/'
      path: '/formations'
      fullPath: '/formations'
      preLoaderRoute: typeof MainFormationsIndexImport
      parentRoute: typeof MainImport
    }
    '/_public/formation/': {
      id: '/_public/formation/'
      path: '/formation'
      fullPath: '/formation'
      preLoaderRoute: typeof PublicFormationIndexImport
      parentRoute: typeof PublicImport
    }
    '/_main/profil/': {
      id: '/_main/profil/'
      path: '/profil'
      fullPath: '/profil'
      preLoaderRoute: typeof MainProfilIndexLazyImport
      parentRoute: typeof MainImport
    }
    '/_public/cookies/': {
      id: '/_public/cookies/'
      path: '/cookies'
      fullPath: '/cookies'
      preLoaderRoute: typeof PublicCookiesIndexLazyImport
      parentRoute: typeof PublicImport
    }
    '/_public/declaration-accessiblite/': {
      id: '/_public/declaration-accessiblite/'
      path: '/declaration-accessiblite'
      fullPath: '/declaration-accessiblite'
      preLoaderRoute: typeof PublicDeclarationAccessibliteIndexLazyImport
      parentRoute: typeof PublicImport
    }
    '/_public/plan-du-site/': {
      id: '/_public/plan-du-site/'
      path: '/plan-du-site'
      fullPath: '/plan-du-site'
      preLoaderRoute: typeof PublicPlanDuSiteIndexLazyImport
      parentRoute: typeof PublicImport
    }
    '/_main/eleve/_inscription/inscription/confirmation/': {
      id: '/_main/eleve/_inscription/inscription/confirmation/'
      path: '/inscription/confirmation'
      fullPath: '/eleve/inscription/confirmation'
      preLoaderRoute: typeof MainEleveInscriptionInscriptionConfirmationIndexLazyImport
      parentRoute: typeof MainEleveInscriptionImport
    }
    '/_main/eleve/_inscription/inscription/domaines/': {
      id: '/_main/eleve/_inscription/inscription/domaines/'
      path: '/inscription/domaines'
      fullPath: '/eleve/inscription/domaines'
      preLoaderRoute: typeof MainEleveInscriptionInscriptionDomainesIndexLazyImport
      parentRoute: typeof MainEleveInscriptionImport
    }
    '/_main/eleve/_inscription/inscription/etude/': {
      id: '/_main/eleve/_inscription/inscription/etude/'
      path: '/inscription/etude'
      fullPath: '/eleve/inscription/etude'
      preLoaderRoute: typeof MainEleveInscriptionInscriptionEtudeIndexLazyImport
      parentRoute: typeof MainEleveInscriptionImport
    }
    '/_main/eleve/_inscription/inscription/formations/': {
      id: '/_main/eleve/_inscription/inscription/formations/'
      path: '/inscription/formations'
      fullPath: '/eleve/inscription/formations'
      preLoaderRoute: typeof MainEleveInscriptionInscriptionFormationsIndexLazyImport
      parentRoute: typeof MainEleveInscriptionImport
    }
    '/_main/eleve/_inscription/inscription/interets/': {
      id: '/_main/eleve/_inscription/inscription/interets/'
      path: '/inscription/interets'
      fullPath: '/eleve/inscription/interets'
      preLoaderRoute: typeof MainEleveInscriptionInscriptionInteretsIndexLazyImport
      parentRoute: typeof MainEleveInscriptionImport
    }
    '/_main/eleve/_inscription/inscription/metiers/': {
      id: '/_main/eleve/_inscription/inscription/metiers/'
      path: '/inscription/metiers'
      fullPath: '/eleve/inscription/metiers'
      preLoaderRoute: typeof MainEleveInscriptionInscriptionMetiersIndexLazyImport
      parentRoute: typeof MainEleveInscriptionImport
    }
    '/_main/eleve/_inscription/inscription/projet/': {
      id: '/_main/eleve/_inscription/inscription/projet/'
      path: '/inscription/projet'
      fullPath: '/eleve/inscription/projet'
      preLoaderRoute: typeof MainEleveInscriptionInscriptionProjetIndexLazyImport
      parentRoute: typeof MainEleveInscriptionImport
    }
    '/_main/eleve/_inscription/inscription/scolarite/': {
      id: '/_main/eleve/_inscription/inscription/scolarite/'
      path: '/inscription/scolarite'
      fullPath: '/eleve/inscription/scolarite'
      preLoaderRoute: typeof MainEleveInscriptionInscriptionScolariteIndexLazyImport
      parentRoute: typeof MainEleveInscriptionImport
    }
  }
}

// Create and export the route tree

interface MainEleveInscriptionRouteChildren {
  MainEleveInscriptionInscriptionConfirmationIndexLazyRoute: typeof MainEleveInscriptionInscriptionConfirmationIndexLazyRoute
  MainEleveInscriptionInscriptionDomainesIndexLazyRoute: typeof MainEleveInscriptionInscriptionDomainesIndexLazyRoute
  MainEleveInscriptionInscriptionEtudeIndexLazyRoute: typeof MainEleveInscriptionInscriptionEtudeIndexLazyRoute
  MainEleveInscriptionInscriptionFormationsIndexLazyRoute: typeof MainEleveInscriptionInscriptionFormationsIndexLazyRoute
  MainEleveInscriptionInscriptionInteretsIndexLazyRoute: typeof MainEleveInscriptionInscriptionInteretsIndexLazyRoute
  MainEleveInscriptionInscriptionMetiersIndexLazyRoute: typeof MainEleveInscriptionInscriptionMetiersIndexLazyRoute
  MainEleveInscriptionInscriptionProjetIndexLazyRoute: typeof MainEleveInscriptionInscriptionProjetIndexLazyRoute
  MainEleveInscriptionInscriptionScolariteIndexLazyRoute: typeof MainEleveInscriptionInscriptionScolariteIndexLazyRoute
}

const MainEleveInscriptionRouteChildren: MainEleveInscriptionRouteChildren = {
  MainEleveInscriptionInscriptionConfirmationIndexLazyRoute:
    MainEleveInscriptionInscriptionConfirmationIndexLazyRoute,
  MainEleveInscriptionInscriptionDomainesIndexLazyRoute:
    MainEleveInscriptionInscriptionDomainesIndexLazyRoute,
  MainEleveInscriptionInscriptionEtudeIndexLazyRoute:
    MainEleveInscriptionInscriptionEtudeIndexLazyRoute,
  MainEleveInscriptionInscriptionFormationsIndexLazyRoute:
    MainEleveInscriptionInscriptionFormationsIndexLazyRoute,
  MainEleveInscriptionInscriptionInteretsIndexLazyRoute:
    MainEleveInscriptionInscriptionInteretsIndexLazyRoute,
  MainEleveInscriptionInscriptionMetiersIndexLazyRoute:
    MainEleveInscriptionInscriptionMetiersIndexLazyRoute,
  MainEleveInscriptionInscriptionProjetIndexLazyRoute:
    MainEleveInscriptionInscriptionProjetIndexLazyRoute,
  MainEleveInscriptionInscriptionScolariteIndexLazyRoute:
    MainEleveInscriptionInscriptionScolariteIndexLazyRoute,
}

const MainEleveInscriptionRouteWithChildren =
  MainEleveInscriptionRoute._addFileChildren(MainEleveInscriptionRouteChildren)

interface MainEleveRouteChildren {
  MainEleveInscriptionRoute: typeof MainEleveInscriptionRouteWithChildren
}

const MainEleveRouteChildren: MainEleveRouteChildren = {
  MainEleveInscriptionRoute: MainEleveInscriptionRouteWithChildren,
}

const MainEleveRouteWithChildren = MainEleveRoute._addFileChildren(
  MainEleveRouteChildren,
)

interface MainRouteChildren {
  MainMainRoute: typeof MainMainRoute
  MainIndexRoute: typeof MainIndexRoute
  MainEleveRoute: typeof MainEleveRouteWithChildren
  MainFavorisIndexRoute: typeof MainFavorisIndexRoute
  MainFormationsIndexRoute: typeof MainFormationsIndexRoute
  MainProfilIndexLazyRoute: typeof MainProfilIndexLazyRoute
}

const MainRouteChildren: MainRouteChildren = {
  MainMainRoute: MainMainRoute,
  MainIndexRoute: MainIndexRoute,
  MainEleveRoute: MainEleveRouteWithChildren,
  MainFavorisIndexRoute: MainFavorisIndexRoute,
  MainFormationsIndexRoute: MainFormationsIndexRoute,
  MainProfilIndexLazyRoute: MainProfilIndexLazyRoute,
}

const MainRouteWithChildren = MainRoute._addFileChildren(MainRouteChildren)

interface PublicRouteChildren {
  PublicFormationIndexRoute: typeof PublicFormationIndexRoute
  PublicCookiesIndexLazyRoute: typeof PublicCookiesIndexLazyRoute
  PublicDeclarationAccessibliteIndexLazyRoute: typeof PublicDeclarationAccessibliteIndexLazyRoute
  PublicPlanDuSiteIndexLazyRoute: typeof PublicPlanDuSiteIndexLazyRoute
}

const PublicRouteChildren: PublicRouteChildren = {
  PublicFormationIndexRoute: PublicFormationIndexRoute,
  PublicCookiesIndexLazyRoute: PublicCookiesIndexLazyRoute,
  PublicDeclarationAccessibliteIndexLazyRoute:
    PublicDeclarationAccessibliteIndexLazyRoute,
  PublicPlanDuSiteIndexLazyRoute: PublicPlanDuSiteIndexLazyRoute,
}

const PublicRouteWithChildren =
  PublicRoute._addFileChildren(PublicRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof MainMainRoute
  '/': typeof MainIndexRoute
  '/connexion': typeof ConnexionIndexRoute
  '/parcoursup-callback': typeof ParcoursupCallbackIndexRoute
  '/eleve': typeof MainEleveInscriptionRouteWithChildren
  '/favoris': typeof MainFavorisIndexRoute
  '/formations': typeof MainFormationsIndexRoute
  '/formation': typeof PublicFormationIndexRoute
  '/profil': typeof MainProfilIndexLazyRoute
  '/cookies': typeof PublicCookiesIndexLazyRoute
  '/declaration-accessiblite': typeof PublicDeclarationAccessibliteIndexLazyRoute
  '/plan-du-site': typeof PublicPlanDuSiteIndexLazyRoute
  '/eleve/inscription/confirmation': typeof MainEleveInscriptionInscriptionConfirmationIndexLazyRoute
  '/eleve/inscription/domaines': typeof MainEleveInscriptionInscriptionDomainesIndexLazyRoute
  '/eleve/inscription/etude': typeof MainEleveInscriptionInscriptionEtudeIndexLazyRoute
  '/eleve/inscription/formations': typeof MainEleveInscriptionInscriptionFormationsIndexLazyRoute
  '/eleve/inscription/interets': typeof MainEleveInscriptionInscriptionInteretsIndexLazyRoute
  '/eleve/inscription/metiers': typeof MainEleveInscriptionInscriptionMetiersIndexLazyRoute
  '/eleve/inscription/projet': typeof MainEleveInscriptionInscriptionProjetIndexLazyRoute
  '/eleve/inscription/scolarite': typeof MainEleveInscriptionInscriptionScolariteIndexLazyRoute
}

export interface FileRoutesByTo {
  '': typeof MainMainRoute
  '/': typeof MainIndexRoute
  '/connexion': typeof ConnexionIndexRoute
  '/parcoursup-callback': typeof ParcoursupCallbackIndexRoute
  '/eleve': typeof MainEleveInscriptionRouteWithChildren
  '/favoris': typeof MainFavorisIndexRoute
  '/formations': typeof MainFormationsIndexRoute
  '/formation': typeof PublicFormationIndexRoute
  '/profil': typeof MainProfilIndexLazyRoute
  '/cookies': typeof PublicCookiesIndexLazyRoute
  '/declaration-accessiblite': typeof PublicDeclarationAccessibliteIndexLazyRoute
  '/plan-du-site': typeof PublicPlanDuSiteIndexLazyRoute
  '/eleve/inscription/confirmation': typeof MainEleveInscriptionInscriptionConfirmationIndexLazyRoute
  '/eleve/inscription/domaines': typeof MainEleveInscriptionInscriptionDomainesIndexLazyRoute
  '/eleve/inscription/etude': typeof MainEleveInscriptionInscriptionEtudeIndexLazyRoute
  '/eleve/inscription/formations': typeof MainEleveInscriptionInscriptionFormationsIndexLazyRoute
  '/eleve/inscription/interets': typeof MainEleveInscriptionInscriptionInteretsIndexLazyRoute
  '/eleve/inscription/metiers': typeof MainEleveInscriptionInscriptionMetiersIndexLazyRoute
  '/eleve/inscription/projet': typeof MainEleveInscriptionInscriptionProjetIndexLazyRoute
  '/eleve/inscription/scolarite': typeof MainEleveInscriptionInscriptionScolariteIndexLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_main': typeof MainRouteWithChildren
  '/_public': typeof PublicRouteWithChildren
  '/_main/_main': typeof MainMainRoute
  '/_main/': typeof MainIndexRoute
  '/connexion/': typeof ConnexionIndexRoute
  '/parcoursup-callback/': typeof ParcoursupCallbackIndexRoute
  '/_main/eleve': typeof MainEleveRouteWithChildren
  '/_main/eleve/_inscription': typeof MainEleveInscriptionRouteWithChildren
  '/_main/favoris/': typeof MainFavorisIndexRoute
  '/_main/formations/': typeof MainFormationsIndexRoute
  '/_public/formation/': typeof PublicFormationIndexRoute
  '/_main/profil/': typeof MainProfilIndexLazyRoute
  '/_public/cookies/': typeof PublicCookiesIndexLazyRoute
  '/_public/declaration-accessiblite/': typeof PublicDeclarationAccessibliteIndexLazyRoute
  '/_public/plan-du-site/': typeof PublicPlanDuSiteIndexLazyRoute
  '/_main/eleve/_inscription/inscription/confirmation/': typeof MainEleveInscriptionInscriptionConfirmationIndexLazyRoute
  '/_main/eleve/_inscription/inscription/domaines/': typeof MainEleveInscriptionInscriptionDomainesIndexLazyRoute
  '/_main/eleve/_inscription/inscription/etude/': typeof MainEleveInscriptionInscriptionEtudeIndexLazyRoute
  '/_main/eleve/_inscription/inscription/formations/': typeof MainEleveInscriptionInscriptionFormationsIndexLazyRoute
  '/_main/eleve/_inscription/inscription/interets/': typeof MainEleveInscriptionInscriptionInteretsIndexLazyRoute
  '/_main/eleve/_inscription/inscription/metiers/': typeof MainEleveInscriptionInscriptionMetiersIndexLazyRoute
  '/_main/eleve/_inscription/inscription/projet/': typeof MainEleveInscriptionInscriptionProjetIndexLazyRoute
  '/_main/eleve/_inscription/inscription/scolarite/': typeof MainEleveInscriptionInscriptionScolariteIndexLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/'
    | '/connexion'
    | '/parcoursup-callback'
    | '/eleve'
    | '/favoris'
    | '/formations'
    | '/formation'
    | '/profil'
    | '/cookies'
    | '/declaration-accessiblite'
    | '/plan-du-site'
    | '/eleve/inscription/confirmation'
    | '/eleve/inscription/domaines'
    | '/eleve/inscription/etude'
    | '/eleve/inscription/formations'
    | '/eleve/inscription/interets'
    | '/eleve/inscription/metiers'
    | '/eleve/inscription/projet'
    | '/eleve/inscription/scolarite'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/'
    | '/connexion'
    | '/parcoursup-callback'
    | '/eleve'
    | '/favoris'
    | '/formations'
    | '/formation'
    | '/profil'
    | '/cookies'
    | '/declaration-accessiblite'
    | '/plan-du-site'
    | '/eleve/inscription/confirmation'
    | '/eleve/inscription/domaines'
    | '/eleve/inscription/etude'
    | '/eleve/inscription/formations'
    | '/eleve/inscription/interets'
    | '/eleve/inscription/metiers'
    | '/eleve/inscription/projet'
    | '/eleve/inscription/scolarite'
  id:
    | '__root__'
    | '/_main'
    | '/_public'
    | '/_main/_main'
    | '/_main/'
    | '/connexion/'
    | '/parcoursup-callback/'
    | '/_main/eleve'
    | '/_main/eleve/_inscription'
    | '/_main/favoris/'
    | '/_main/formations/'
    | '/_public/formation/'
    | '/_main/profil/'
    | '/_public/cookies/'
    | '/_public/declaration-accessiblite/'
    | '/_public/plan-du-site/'
    | '/_main/eleve/_inscription/inscription/confirmation/'
    | '/_main/eleve/_inscription/inscription/domaines/'
    | '/_main/eleve/_inscription/inscription/etude/'
    | '/_main/eleve/_inscription/inscription/formations/'
    | '/_main/eleve/_inscription/inscription/interets/'
    | '/_main/eleve/_inscription/inscription/metiers/'
    | '/_main/eleve/_inscription/inscription/projet/'
    | '/_main/eleve/_inscription/inscription/scolarite/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  MainRoute: typeof MainRouteWithChildren
  PublicRoute: typeof PublicRouteWithChildren
  ConnexionIndexRoute: typeof ConnexionIndexRoute
  ParcoursupCallbackIndexRoute: typeof ParcoursupCallbackIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  MainRoute: MainRouteWithChildren,
  PublicRoute: PublicRouteWithChildren,
  ConnexionIndexRoute: ConnexionIndexRoute,
  ParcoursupCallbackIndexRoute: ParcoursupCallbackIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_main",
        "/_public",
        "/connexion/",
        "/parcoursup-callback/"
      ]
    },
    "/_main": {
      "filePath": "_main.tsx",
      "children": [
        "/_main/_main",
        "/_main/",
        "/_main/eleve",
        "/_main/favoris/",
        "/_main/formations/",
        "/_main/profil/"
      ]
    },
    "/_public": {
      "filePath": "_public.tsx",
      "children": [
        "/_public/formation/",
        "/_public/cookies/",
        "/_public/declaration-accessiblite/",
        "/_public/plan-du-site/"
      ]
    },
    "/_main/_main": {
      "filePath": "_main/_main.tsx",
      "parent": "/_main"
    },
    "/_main/": {
      "filePath": "_main/index.tsx",
      "parent": "/_main"
    },
    "/connexion/": {
      "filePath": "connexion/index.tsx"
    },
    "/parcoursup-callback/": {
      "filePath": "parcoursup-callback/index.tsx"
    },
    "/_main/eleve": {
      "filePath": "_main/eleve",
      "parent": "/_main",
      "children": [
        "/_main/eleve/_inscription"
      ]
    },
    "/_main/eleve/_inscription": {
      "filePath": "_main/eleve/_inscription.tsx",
      "parent": "/_main/eleve",
      "children": [
        "/_main/eleve/_inscription/inscription/confirmation/",
        "/_main/eleve/_inscription/inscription/domaines/",
        "/_main/eleve/_inscription/inscription/etude/",
        "/_main/eleve/_inscription/inscription/formations/",
        "/_main/eleve/_inscription/inscription/interets/",
        "/_main/eleve/_inscription/inscription/metiers/",
        "/_main/eleve/_inscription/inscription/projet/",
        "/_main/eleve/_inscription/inscription/scolarite/"
      ]
    },
    "/_main/favoris/": {
      "filePath": "_main/favoris/index.tsx",
      "parent": "/_main"
    },
    "/_main/formations/": {
      "filePath": "_main/formations/index.tsx",
      "parent": "/_main"
    },
    "/_public/formation/": {
      "filePath": "_public/formation/index.tsx",
      "parent": "/_public"
    },
    "/_main/profil/": {
      "filePath": "_main/profil/index.lazy.tsx",
      "parent": "/_main"
    },
    "/_public/cookies/": {
      "filePath": "_public/cookies/index.lazy.tsx",
      "parent": "/_public"
    },
    "/_public/declaration-accessiblite/": {
      "filePath": "_public/declaration-accessiblite/index.lazy.tsx",
      "parent": "/_public"
    },
    "/_public/plan-du-site/": {
      "filePath": "_public/plan-du-site/index.lazy.tsx",
      "parent": "/_public"
    },
    "/_main/eleve/_inscription/inscription/confirmation/": {
      "filePath": "_main/eleve/_inscription/inscription/confirmation/index.lazy.tsx",
      "parent": "/_main/eleve/_inscription"
    },
    "/_main/eleve/_inscription/inscription/domaines/": {
      "filePath": "_main/eleve/_inscription/inscription/domaines/index.lazy.tsx",
      "parent": "/_main/eleve/_inscription"
    },
    "/_main/eleve/_inscription/inscription/etude/": {
      "filePath": "_main/eleve/_inscription/inscription/etude/index.lazy.tsx",
      "parent": "/_main/eleve/_inscription"
    },
    "/_main/eleve/_inscription/inscription/formations/": {
      "filePath": "_main/eleve/_inscription/inscription/formations/index.lazy.tsx",
      "parent": "/_main/eleve/_inscription"
    },
    "/_main/eleve/_inscription/inscription/interets/": {
      "filePath": "_main/eleve/_inscription/inscription/interets/index.lazy.tsx",
      "parent": "/_main/eleve/_inscription"
    },
    "/_main/eleve/_inscription/inscription/metiers/": {
      "filePath": "_main/eleve/_inscription/inscription/metiers/index.lazy.tsx",
      "parent": "/_main/eleve/_inscription"
    },
    "/_main/eleve/_inscription/inscription/projet/": {
      "filePath": "_main/eleve/_inscription/inscription/projet/index.lazy.tsx",
      "parent": "/_main/eleve/_inscription"
    },
    "/_main/eleve/_inscription/inscription/scolarite/": {
      "filePath": "_main/eleve/_inscription/inscription/scolarite/index.lazy.tsx",
      "parent": "/_main/eleve/_inscription"
    }
  }
}
ROUTE_MANIFEST_END */
